import { URLs } from "../../api/URLs";
import axios from "../../api/axios";

export const add_new_branch = async (token, params) => {
  const response = await axios
    .post(
      URLs.ADD_COMPANY_BRANCH,
      JSON.stringify({
        name: params.name,
        address: params.address,
      }),
      {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error add branch calls");

      throw error;
    });
  return response;
};
export const update_branch = async (token, params) => {
  const response = await axios
    .post(
      URLs.UPDATE_COMPANY_BRANCH,
      JSON.stringify({
        name: params.name,
        address: params.address,
        id: params.id,
        status: params.status,
        email: params.email,
      }),
      {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error update branch calls");

      throw error;
    });
  return response;
};

/////////////////////////CATERGORIES PAGE

export const fetch_company_category = async (token, params) => {
  const response = await axios
    .get(
      URLs.FETCH_COMPANY_CATEGORY,

      {
        params: { company_id: params },
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error get items calls");

      throw error;
    });
  return response;
};
export const add_company_category = async (token, params) => {
  const response = await axios
    .post(
      URLs.ADD_COMPANY_CATEGORY,
      JSON.stringify({
        name: params.name,
      }),
      {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error add category calls");

      throw error;
    });
  return response;
};
export const delete_company_category = async (token, params) => {
  console.log(token);
  const response = await axios
    .post(
      URLs.DELETE_COMPANY_CATEGORY,
      JSON.stringify({
        category: params,
      }),
      {
        // params: { category: params },
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error delete category calls");

      throw error;
    });
  return response;
};
export const disable_company_category = async (token, params) => {
  const response = await axios
    .get(
      URLs.DISABLE_COMPANY_CATEGORY,

      {
        params: { company_id: params },
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error disable category calls");

      throw error;
    });
  return response;
};
export const update_company_category = async (token, params) => {
  const response = await axios
    .post(
      URLs.UPDATE_COMPANY_CATEGORY,
      JSON.stringify({
        name: params.name,
        id: params.id,
        status: params.status,
      }),
      {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error udpate cateory calls");

      throw error;
    });
  return response;
};

///////////////////////////////// ITEM
export const add_new_category = async (token, params) => {
  const response = await axios
    .post(
      URLs.ADD_COMPANY_CATEGORY,
      JSON.stringify({
        name: params.name,
      }),
      {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error add category calls");

      throw error;
    });
  return response;
};

export const fetch_company_items = async (token, params) => {
  const response = await axios
    .get(
      URLs.GET_COMPANY_ITEMS,

      {
        params: { company: params },
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error get items calls");

      throw error;
    });
  return response;
};

export const add_new_item = async (token, params) => {
  const response = await axios
    .post(
      URLs.ADD_NEW_ITEM,
      JSON.stringify({
        name: params.name,
        branches: params.branches,
        category_id: params.category,
        image: params.image,
        variationTypes: params.variationTypes,
        addonTypes: params.addonTypes,
        description: params.description,
      }),
      {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error add category calls");

      throw error;
    });
  return response;
};

export const update_item = async (token, params) => {
  const response = await axios
    .post(
      URLs.UPDATE_ITEM,
      JSON.stringify({
        item_id: params.id,
        name: params.name,
        branches: params.branches,
        category_id: params.category,
        image: params.image,
        variationTypes: params.variationTypes,
        addonTypes: params.addonTypes,
        description: params.description,
      }),
      {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error add category calls");

      throw error;
    });
  return response;
};

export const delelte_row = async (token, id, api) => {
  console.log(token);
  const response = await axios
    .post(
      api,
      JSON.stringify({
        id: id,
      }),
      {
        // params: { category: params },
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error delete category calls");

      throw error;
    });
  return response;
};

export const update_row_status = async (token, id, api, status) => {
  // console.log(token, id, api, status)
  const response = await axios
    .post(
      api,
      JSON.stringify({
        id: id,
        status: status,
      }),
      {
        // params: { category: params },
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error updaterow sstatus");

      throw error;
    });
  return response;
};

/////////////////////////////////// SERVICE PAGE
export const getCompanyServices = async (token) => {
  // console.log(token, id, api, status)
  const response = await axios
    .get(URLs.GET_COMPANY_SERVICES, {
      headers: { "Content-Type": "application/json", "x-auth-token": token },
      withCredentials: true,
    })
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error updaterow sstatus");

      throw error;
    });
  return response;
};
export const updateCompanyService = async (
  token,
  api,
  branch_id,
  service_id
) => {
  // console.log(token, id, api, status)
  const response = await axios
    .post(
      api,
      JSON.stringify({
        branch_id,
        service_id,
      }),
      {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error updaterow sstatus");

      throw error;
    });
  return response;
};
export const getPaymentMethodStatus = async (token) => {
  // console.log(token, id, api, status)
  const response = await axios
    .get(
      URLs.GET_PAYMENT_METHODS,

      {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error getting companyPaymentMethods");

      throw error;
    });
  return response;
};

export const updateChapaKey = async (token, secretKey) => {
  // console.log(token, id, api, status)
  const response = await axios
    .post(
      URLs.UPDATE_CHAPA_KEY,
      JSON.stringify({
        secretKey: secretKey,
      }),
      {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error updaterow sstatus");

      throw error;
    });
  return response;
};

export const fetchCoupons = async (token) => {
  // console.log(token, id, api, status)
  const response = await axios
    .get(
      URLs.GET_COUPONS,

      { 
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      console.log(res.data?.Message)
      return res?.data?.Message;
    })
    .catch((error) => {
      console.log(error, "error getting companyPaymentMethods");

      throw error;
    });
  return response;
};
export const addCoupon = async (token,params) => {
  // console.log(token, id, api, status)
  const response = await axios
    .post(
      URLs.CREATE_COUPON,
        JSON.stringify(params),
      { 
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      console.log(res.data?.Message)
      return res?.data?.Message;
    })
    .catch((error) => {
      console.log(error, "error getting companyPaymentMethods");

      throw error;
    });
  return response;
};

export const updateCouponStatus = async (token,params) => {
  // console.log(token, id, api, status)
  const response = await axios
    .post(
      URLs.UPDATE_COMPANY_COUPON_STATUS,
        JSON.stringify(params),
      { 
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      }
    )
    .then((res) => {
      console.log(res.data?.Message)
      return res?.data?.Message;
    })
    .catch((error) => {
      console.log(error, "error getting companyPaymentMethods");

      throw error;
    });
  return response;
};





// DASHBOARD
export const get_analytics = async (params) => {
  // console.log(token, id, api, status)
  const response = await axios.post(URLs.GET_ANALYTICS, JSON.stringify(params), {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": params.token,
      },
      withCredentials: true,
    })
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error updaterow sstatus");

      throw error;
    });
  return response;
};
export const get_branch_for_analytics = async (params) => {
  // console.log(token, id, api, status)
  const response = await axios.get(URLs.GET_BRANCH_FOR_ANA, {
    params:{params},
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": params.token,
      },
      withCredentials: true,
    })
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error updaterow sstatus");

      throw error;
    });
  return response;
};
