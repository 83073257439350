export const URLs = {
  LOGIN: "/api/login",

  // PRELOAD
  PRELOAD_STATE: "/api/preload",
  MENU_ADDRESS:"https://qr2menu.qranbessa.net",
  // MENU_ADDRESS: "http://localhost:3001",

  // DEFAULT_API: "http://localhost:3005",
  DEFAULT_API: 'https://qr2api.qranbessa.net',

  CHANGE_PASSWORD: "/api/change/password",

  //ADMIN
  FETCH_ADMIN_DATA: "/api/fetch/admin-data",
  FETCH_COMPANIES: "/api/get-companies",
  FETCH_COMPANIES_FOR_SALES: "/api/get-companies-for-sales",
  ADD_NEW_COMPANY: "/api/register/company",
  ADD_NEW_COMPANY_FOR_SALES: "/api/register/company-for-sales",
  UPDATE_COMPANY_PROFILE: "/api/update/company",
  UPDATE_COMPANY_PROFILE_FOR_SALES: "/api/update/company-for-sales",
  DELETE_COMPANY: "/api/delete/company",
  DELETE_COMPANY_FOR_SALES: "/api/delete/company-for-sales",
  FETCH_ALL_USERS: "/api/fetch/users",
  FETCH_ALL_USERS_FOR_SALES: "/api/fetch/users-for-sales",
  FETCH_COMPANY_BRANCHS: "/api/get-company-branches",
  ADD_NEW_USER: "/api/register/user",
  ADD_NEW_USER_FOR_SALES: "/api/register/user-for-sales",
  UPDATE_USER_PROFILE: "/api/update/user",
  UPDATE_USER_PROFILE_FOR_SALES: "/api/update/user-for-sales",
  CHECK_USERNAME: "/api/check-username",

  FETCH_SERVICES: "/api/fetch/services",
  ADD_SERVICE: "/api/add/service",
  UPDATE_SERVICE: "/api/update/service",
  UPDATE_SERVICE_STATUS: "/api/update/service-status",
  DELETE_SERVICE: "/api/delete/service",

  // MANAGER

  ADD_COMPANY_BRANCH: "/api/add/branch",
  UPDATE_COMPANY_BRANCH: "/api/update/branch",

  FETCH_COMPANY_CATEGORY: "/api/fetch/categories",
  ADD_COMPANY_CATEGORY: "/api/add/category",
  UPDATE_COMPANY_CATEGORY: "/api/update/category",
  DISABLE_COMPANY_CATEGORY: "/api/disable/category",
  DELETE_COMPANY_CATEGORY: "/api/delete/category",

  GET_COMPANY_ITEMS: "/api/get/items",
  ADD_NEW_ITEM: "/api/add/item",
  UPDATE_ITEM: "/api/update/item",
  DELETE_ITEM: "/api/delete/item",

  UPDATE_ITEM_STATUS: "/api/update/item-status",

  GET_COMPANY_SERVICES: "/api/get/company-services",
  ACTIVATE_SERVICE: "api/activate/branch-service",
  DEACTIVATE_SERVICE: "api/deactivate/branch-service",

  GET_PAYMENT_METHODS: "api/get/company-payment-methods",
  UPDATE_COMPANY_PAYMENT_METHODS: "api/update/company-payment-methods",

  UPDATE_SALES_PRIVILAGE:"api/update/sales-privilage",
  UPDATE_CHAPA_KEY:"api/update/chapa-key",
  GET_ANALYTICS:"api/get/analytics",
  GET_BRANCH_FOR_ANA:"api/get/branch/for/ana",

  GET_COUPONS:"api/fetch-coupon",
  CREATE_COUPON:"api/create-coupon",
  UPDATE_COUPON:"api/update-coupon",

  UPDATE_COMPANY_COUPON_STATUS:"api/company-coupon-status",



  // MENU

  FETCH_ITEMS: "/api/get/menu",

  // CASHIERS
  FETCH_ORDERS: "/api/get/orders",
  FETCH_RESERVATION: "/api/get/reservations",
  UPDATE_ORDER: {
    1: "/api/move-order-to-kitchen",
    6: "/api/move-order-to-canceled",
    7: "/api/move-order-to-completed",
  },
  CLEAR_CANCELED_ORDERS: "/api/clear-canceled",
  TURN_ON_PRINTER:"/api/update/is-printing/on",
  TURN_OFF_PRINTER:"/api/update/is-printing/off",

};
